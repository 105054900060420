<template>
  <v-card
    class="emptyLayoutBg pr-sm-20 pr-md-0 rounded-0"
    flat
    :max-width="checkSm ? 450 : 280"
  >
    <div
      class="text-uppercase pb-16 pb-sm-33 mr-sm-n2 loginTitle--text"
      :class="checkSm ? 'font-large-login text-right' : 'font-row-title'"
    >welcome to dialics</div>

    <v-form v-model="validForm" ref="form" v-if="twoFactorAuthDisabled">
      <v-row no-gutters>
        <v-col
          class="d-flex flex-column justify-center pb-4 pb-sm-10"
          :class="{ 'align-end': checkSm }"
          cols="12"
          sm="4"
        >
          <span class="font-normal loginColor--text">Email Address</span>
          <portal-target
            class="field-message font-normal error--text text-sm-right"
            name="email"
          ></portal-target>
        </v-col>
        
        <v-col class="pb-10 pl-sm-11" cols="12" sm="8">
          <FormField
            v-model="formData.email"
            class="login-form-input"
            :rules="emailRules"
          >
            <template #message="{ key, message }">
              <portal to="email" v-if="!!message">
                {{ message }}
              </portal>
            </template>
          </FormField>
        </v-col>
        
        <v-col
          class="d-flex flex-column justify-center pb-4 pb-sm-10"
          :class="{ 'align-end': checkSm }"
          cols="12"
          sm="4"
        >
          <span class="font-normal loginColor--text">Password</span>
          <portal-target
            class="field-message font-normal error--text text-sm-right"
            name="userPassword"
          ></portal-target>
        </v-col>
        
        <v-col class="pb-10 pl-sm-11" cols="12" sm="8">
          <FormField
            v-model="formData.password"
            class="login-form-input"
            type="password"
            :rules="passwordRules"
          >
            <template #message="{ key, message }">
              <portal to="userPassword" v-if="!!message">
                {{ message }}
              </portal>
            </template>
          </FormField>
        </v-col>
        
        <v-col class="pl-12 d-flex justify-end align-center" cols="12">
          <div class="font-xs textLink--text pr-10">
            <TextLink @click="goToRegister">Register</TextLink>
            <span class="px-4 loginLinkColor--text">|</span>
            <TextLink @click="goToReset">Reset Password</TextLink>
          </div>
          
          <ActionBtnForDarkBg
            type="submit"
            @click.prevent="submitForm"
            :loading="loading"
          >
            Login
          </ActionBtnForDarkBg>
        </v-col>
      </v-row>
    </v-form>

    <v-form v-model="twoFactorAuthValidForm" ref="twoFAForm" v-else>
      <v-row no-gutters>
        <v-col
          class="d-flex flex-column justify-center pb-4 pb-sm-10"
          :class="{ 'align-end': checkSm }"
          cols="12"
          sm="4"
        >
          <span class="font-normal loginColor--text">Two-step verification</span>
          <portal-target
            class="field-message font-normal error--text text-sm-right"
            name="loginPhoneCode"
          ></portal-target>
        </v-col>

        <v-col class="pb-10 pl-sm-11" cols="12" sm="8">
          <FormField
            v-model="code"
            class="login-form-input"
            :rules="rules"
          >
            <template #message="{ key, message }">
              <portal to="loginPhoneCode" v-if="!!message">
                {{ message }}
              </portal>
            </template>
          </FormField>
        </v-col>

        <v-col class="pl-12 pb-12" cols="12" sm="8" offset-sm="4">
          <div class="font-xs textLink--text">
            Open the two-step verification app on your mobile device to get your verification code
          </div>
        </v-col>

        <v-col class="pb-12 pl-sm-11 d-flex justify-end" cols="12" sm="8" offset-sm="4">
          <ActionBtnForDarkBg
            type="submit"
            @click.prevent="submit2faForm"
            :loading="loading"
          >
            Send
          </ActionBtnForDarkBg>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>
import FormField from '@/components/inputs/FormField'
import ActionBtnForDarkBg from '@/components/buttons/ActionBtnForDarkBg'
import TextLink from '@/components/links/TextLink'

import { mapState, mapMutations, mapActions } from 'vuex'
import { Types as authTypes } from '@/store/modules/auth'

import { getLogin } from '@/api/auth-routes'
import { setAccessToken, getAccountId, setAccountId, setUserNameAndEmailForCrispChat } from '@/helper/auth-helper'
import { sendGaEvent } from '@/helper/app-helper'

export default {
  name: 'AuthLogin',
  inheritAttrs: false,
  components: { FormField, ActionBtnForDarkBg, TextLink },
  data: () => ({
    loading: false,
    twoFactorAuthDisabled: true,
    validForm: true,
    twoFactorAuthValidForm: true,
    formData: {
      email: null,
      password: null
    },
    code: null,
    emailRules: [],
    passwordRules: [],
    rules: [],
  }),
  computed: {
    ...mapState({
      role: state => state.auth.role,
      pbx: state => state.auth.pbx,
      subscription: state => state.auth.subscription,
      redirectAccountBlocked: state => state.auth.redirectAccountBlocked,
    }),
    checkMd() {
      return this.$vuetify.breakpoint.width > 1029
    },
    checkSm() {
      return this.$vuetify.breakpoint.width > 599
    },
  },
  mounted() {
    if (!!this.$gtag) {
      this.$gtag.pageview({ page_path: '/login' })
    }
    sendGaEvent('login-page', 'login-page')
  },
  methods: {
    ...mapMutations({
      setSelectAccounts: authTypes.mutations.SET_SELECT_ACCOUNTS,
      setAccounts: authTypes.mutations.SET_ACCOUNTS,
      setUser: authTypes.mutations.SET_USER,
      setAccountRole: authTypes.mutations.SET_ACCOUNT_ROLE,
      setAccountTimezone: authTypes.mutations.SET_ACCOUNT_TIMEZONE,
    }),
    ...mapActions({
      checkUserSubAccount: authTypes.actions.CHECK_USER_SUB_ACCOUNT,
      loadRoles: authTypes.actions.GET_LOAD_ROLES,
    }),
    async submitForm() {
      if (this.twoFactorAuthDisabled) {
        await this.validationForm()
      }

      if (!this.twoFactorAuthDisabled || this.$refs.form.validate()) {
        this.loading = true
        const { success, payload, message } = await getLogin(this.twoFactorAuthDisabled
          ? this.formData
          : Object.assign({}, this.formData, { 'two_factor_code': this.code }))

        if (success) {
          if (payload['two_factor_enabled'] === true) {
            this.twoFactorAuthDisabled = false
            this.loading = false
            return
          }

          setAccessToken(payload['access_token'])
          this.loadRoles()

          const user = payload.user
          this.setAccounts(payload.accounts)
          this.setUser(payload.user)

          if (!!user && user.hasOwnProperty('select') && !!user.select) {
            this.setAccountRole('select_accounts')
            this.setSelectAccounts(user.select)
          } else {
            this.checkAccount(payload.accounts)
            setUserNameAndEmailForCrispChat({
              name: payload.user['first_name'],
              email: payload.user.email
            })
          }

          this.$notify({ type: 'success-bg', text: message })
        } else {
          !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
        }

        this.loading = false
      }
    },
    async submit2faForm() {
      await this.validate2faFrom()
      if (this.$refs.twoFAForm.validate()) {
        this.submitForm()
      }
    },
    validationForm() {
      this.emailRules = [
        v => !!v || 'E-mail is required',
        v => /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail must be valid'
      ]
      this.passwordRules = [
        v => !!v || 'Password is required'
      ]
    },
    validate2faFrom() {
      this.rules = [
        v => !!v || 'Code is required'
      ]
    },
    checkAccount(accounts) {
      const currentAccount = accounts.find(account => account.slug === getAccountId())
      if (!currentAccount) {
        setAccountId(accounts[0].slug)
      }

      this.checkUserSubAccount(getAccountId())
    },
    goToReset() {
      this.$router.push({ name: 'AuthReset' })
    },
    goToRegister() {
      this.$router.push({ name: 'Registrations' })
    },
  },
  watch: {
    role: {
      immediate: true,
      handler(role) {
        if (!!role) {
          if (role === 'select_accounts') {
            return
          }

          if (this.redirectAccountBlocked) {
            return this.$router.push({ name: 'AccountDisabled' })
          }

          if (!this.pbx) {
            return this.$router.push({ name: 'ConnectPbx' })
          }

          if (!this.subscription) {
            return this.$router.push({ name: 'Subscription' })
          }

          if (role === 'Manager') {
            return this.$router.push({ name: 'Dashboard' })
          }

          return this.$router.push({ name: 'Reporting' })
        }
      },
    },
  },
}
</script>
